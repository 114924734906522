var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"question column is-half question-credential",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"question-text"},[(_vm.showQuestionCode)?_c('p',{staticClass:"question-text__code"},[_vm._v(" "+_vm._s(_vm.question.code)+" ")]):_vm._e(),_c('p',{staticClass:"question-text__question",domProps:{"innerHTML":_vm._s(_vm.decodeHtml(_vm.question.question[_vm.lang]))}}),(_vm.getFirstError(errors))?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.getFirstError(errors))+" ")]):_c('p',{staticClass:"help"},[_vm._v(_vm._s(_vm.questionHelp))])]),_c('div',{staticClass:"question-content card"},[_c('div',{staticClass:"card-content"},[_vm._l((_vm.question.sub_questions),function(subQuestion,subQuestionIndex){return _c('ValidationProvider',{key:subQuestion.id,staticClass:"my-3",attrs:{"rules":Object.assign({}, _vm.validationRules),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('div',{staticClass:"field"},[_c('div',{staticClass:"field-label is-normal",class:[_vm.isRtl ? 'text-right' : 'text-left']},[_c('label',{staticClass:"label",class:{ 'has-text-danger has-text-weight-bold': errors[0] }},[_c('div',{domProps:{"innerHTML":_vm._s(subQuestion.question[_vm.lang])}})])]),_c('div',{staticClass:"field-body"},[_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"expanded":"","placeholder":_vm.$t("credential.placeholder"),"type":!subQuestionIndex ? 'text': 'password',"value":!subQuestionIndex ? _vm.value['username'] : _vm.value['password'],"disabled":_vm.loading,"use-html5-validation":false,"step":"any"},on:{"input":function($event){return _vm.onChange(
                    !subQuestionIndex ? 'username' : 'password',
                    $event
                  )}}})],1)],1)])]}}],null,true)})}),_c('ValidationProvider',{attrs:{"mode":"passive","rules":{ credential: {response_id: _vm.respondent._id, sid: _vm.sid, username: _vm.value['username'], password: _vm.value['password'], required: !!_vm.qOpts.mandatory } }}},[_c('b-input',{staticClass:"is-hidden"})],1)],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }